import { getCookie } from 'h3';

export default defineNuxtRouteMiddleware(() => {
  const nuxtApp = useNuxtApp()
  const { ssrContext } = nuxtApp;
  if (ssrContext) {
    const cookie = getCookie(ssrContext.event, 'mp-auth');

    if (!cookie) {
      return navigateTo(`/login?redir=${encodeURIComponent('/account')}`)
    }
  }
})